import * as Sentry from '@sentry/nuxt'
import { retrieveLaunchParams } from '@telegram-apps/sdk'
import { ofetch } from 'ofetch'
import { acceptHMRUpdate, defineStore } from 'pinia'

export const useUserStore = defineStore('user', () => {
  /**
   * Current named of the user.
   */
  const user = ref({})
  const token = ref('')
  const isLogin = computed(() => !!token.value)

  /**
   * Changes the current name of the user and saves the one that was used
   * before.
   *
   * @param name - new name to set
   */

  function login(data: object) {
    user.value = data
    token.value = data.token

    try {
      Sentry.setUser(data)
    }
    catch (error) {

    }
  }

  async function autoLogin() {
    try {
      const { initDataRaw } = retrieveLaunchParams()

      const data = await ofetch(`/api/login`, {
        method: 'POST',
        body: {
          authData: initDataRaw,
        },
      })

      if (data) {
        if (data.status === 0) {
          login(data.data)

          return true
        }
        else {
          logout()
          return false
        }
      }
    }
    catch (error) {
      return false
    }
  }

  function logout() {
    user.value = {}
    token.value = ''
    try {
      Sentry.setUser(null)
    }
    catch (error) {

    }
  }

  return {
    isLogin,
    login,
    autoLogin,
    logout,
    user,
    token,
  }
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useUserStore, import.meta.hot))
